<template>
  <div class="account-billing">
    <CurrentPlan :plan="plan.plan" :price="plan.amount" @change-plan="redirectToBilling"/>
    <Billing card-brand="Visa" card-last-number="4576" renew-date="2020-12-13" @edit-card="editCard"/>
    <Invoices :invoices-arr="[{name: 'Basic', price: 10, date: '2020-12-13'}, {name: 'Basic', price: 10, date: '2020-11-13'}]"/>
  </div>
</template>

<script>
import CurrentPlan from "@/components/Account/Billing/CurrentPlan";
import Billing from "@/components/Account/Billing/Billing";
import Invoices from "@/components/Account/Billing/Invoices";
import { ref, computed, inject } from "vue"
import { getSessionIdEditCard } from "@/api/pay";
import {useRouter} from "vue-router"

export default {
  name: "AccountBilling",
  components: {Invoices, Billing, CurrentPlan},
  props: {
    workspaceId: String
  },
  setup () {
    const router = useRouter()
    const stripe = inject('stripe').value
    const subscription = inject('subscription')

    const editCard = async function () {
      const sessionId = (await getSessionIdEditCard()).id

      await stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as argument here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: sessionId
      })
    }

    const redirectToBilling = function () {
      router.push({
        name: "Billing",
        params: {
          workspaceId: '-'
        }
      })
    }

    const nowPlan = computed(() => {
      const amount = subscription.value.plan.amount
      if (amount === 1000)
        return 'basic'
      else if (amount === 2500)
        return 'pro'
      else if (amount === 5000)
        return 'member'
    })

    const plan = computed(() => ({
        plan: nowPlan.value,
        amount: subscription.value.plan.amount
    }))

    return {
      editCard,
      redirectToBilling,
      plan
    }

  }
}
</script>

<style scoped>

</style>