<template>
    <div class="content">
        <h2>Invoices</h2>
        <table>
            <tr>
                <th>Purchased item</th>
                <th>Price</th>
                <th>Date</th>
                <th></th>
            </tr>
            <tr v-for="item in invoicesArr" :key="item">
                <td>{{item.name}} plan</td>
                <td>€{{item.price}}</td>
                <td>{{formatDate(item.date)}}</td>
                <td><span @click="$emit('get-receipt', item)">Get receipt</span></td>
            </tr>
        </table>
    </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "Invoices",
  props: {
      invoicesArr: Array
  },
    setup() {
      const formatDate = function(x) {
          return moment(x).format('MMM DD, YYYY')
      }
      return {formatDate}
  }
};
</script>

<style scoped lang="sass">
@import "~@/assets/styles/color-scheme.scss"
.content
    text-align: left
    padding: 16px 0
    margin-bottom: 24px
    background-color: #fff
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1)
    border-radius: 8px
h2
    margin-bottom: 16px
    margin-left: 16px
table
    width: 100%
    border-collapse: collapse
th, td 
  padding: 16px
  border-bottom: 1px solid #E3E5EC
tr:last-child
    td 
        border: none
td
    &:first-child
        width: 160px
        color: #0CAC60
    &:nth-child(2)
        width: 85px
    &:nth-child(3)
        width: 160px
    &:last-child
        span
          cursor: pointer
          color: #0CAC60
          text-decoration: none
</style>