<template>
  <div class="content">
    <h2>Current plan: {{plan}}, €{{price / 100}}/mo.</h2>
    <p>
      {{plans[planId].description}}<br>
      {{plans[planId].changeDescription}}
    </p>
    <ButtonNew class="button" mode="green" size="l" text="Change plan" @click="$emit('change-plan')"/>
    <ButtonNew mode="red" size="l" text="Cancel subscription" @click="$emit('cancel-subscription')"/>
  </div>
</template>

<script>
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import {computed} from "vue";

export default {
  name: "CurrentPlan",
  components: {ButtonNew},
  props: {
      plan: String,
      price: Number
  },
  setup (props) {
    const plans = {
      basic: {
        description: 'Full access to PhD App.',
        changeDescription: 'Change plan and get access to 1-to-1 coaching sessions and more.'
      },
      pro: {
        description: 'Full access to PhD App.',
        changeDescription: 'Change plan and get access to 1-to-1 coaching sessions and more.'
      },
      member: {
        description: 'All the tools, mentoring and resources you need to achieve your research goals faster and more effectively.',
        changeDescription: ''
      }
    }
    const planId = computed(() => props.plan.toLowerCase().split(" ").join(""))
    return {
      plans,
      planId
    }
  }
};
</script>

<style scoped lang="sass">
@import "~@/assets/styles/color-scheme.scss"
.content
    text-align: left
    padding: 16px
    margin-top: 24px
    margin-bottom: 24px
    background-color: #fff
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.1)
    border-radius: 8px
h2
    margin-bottom: 16px
p 
    margin-bottom: 16px
.button
    margin-right: 8px
</style>